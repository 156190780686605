<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <a-card>
      <template slot="title">
      </template>
        <div class="row">
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
            <strong>Buku Besar</strong>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <v-select
              ref="rekening"
              placeholder="Ketikan Rekening"
              class="style-chooser"
              style="width: 300px;"
              v-model="rekening"
              :options="masterRekening"
              :reduce="(tes) => tes.kode"
              @input="getAllData"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
    <a-range-picker
          class="mr-2"
          style="width: 250px;"
          :allowClear="false"
          :default-value="[moment().startOf('month'), moment()]"
          format="DD-MM-YYYY"
          @change="filterDate"/>
            <a-button-group class="">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Refresh Data</span>
                </template>
                <a-button @click="getAllData(true)">
                  <a-icon type="sync" :spin="loadingRefresh" class="text-success"/>
                </a-button>
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Export Data</span>
                </template>
                <a-button @click="exportExcel">
                  <a-icon type="file-excel" class="text-success"/>
                </a-button>
              </a-tooltip>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Print Preview</span>
            </template>
            <a-button @click="exportPdf">
              <a-icon type="printer" class="text-success"/>
            </a-button>
          </a-tooltip>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Setting Pengesahan</span>
            </template>
            <a-button @click="showModalPengesahan">
              <a-icon type="setting" class="text-secondary"/>
            </a-button>
          </a-tooltip>
            </a-button-group>
          </div>
        </div>
        <a-divider type="horizontal" style="margin: 20px 0 !important;"/>
        <ag-grid-vue
          style="height: 68vh;"
          :class="
            settings.theme === 'dark'
              ? 'ag-theme-balham-dark mt-2'
              : 'ag-theme-balham mt-2'
          "
          :key="componentKey"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :pagination="allowPagination"
          :paginationPageSize="20"
          :cacheQuickFilter="true"
          :quickFilterText="tablequickfilter"
          :accentedSort="false"
          :sortingOrder="['asc', 'desc']"
          :rowData="rowData"
          @grid-ready="onGridReady"
          :singleClickEdit="false"
          @cell-value-changed="onCellValueChanged"
          :undoRedoCellEditing="true"
          :undoRedoCellEditingLimit="5"
          @first-data-rendered="onFirstDataRendered"
          rowSelection="single"
          @selection-changed="onSelectionChanged"
          :rowClassRules="rowClassRules"
        >
        </ag-grid-vue>
    </a-card>
      </div>
    </div>
    <modalpengesahan ref="modalPengesahan"/>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
import NProgress from 'nprogress'
import modalpengesahan from '@/views/app/apps/koperasisyariah/settings/modalpengesahan.vue'

export default {
  components: {
    modalpengesahan,
  },
  computed: mapState(['settings', 'user']),
  created() {
    this.gridOptions = {}
    this.getMaster()
    // this.getAllData()
  },
  data() {
    return {
      loadingRefresh: false,
      tgl1: moment().startOf('month'),
      tgl2: moment(),
      rekening: '',
      componentKey: 0,
      // Property ag-grid
      mode: 'netral',
      theme: this.$store.state.settings.theme,
      gridOptions: null,
      selectedData: null,
      gridApi: null,
      backupRowData: [],
      changedData: [],
      rowData: [],
      masterRekening: [],
      tablequickfilter: '',
      allowPagination: false,
      columnDefs: [
        {
          headerName: 'Tanggal',
          field: 'tgl',
          editable: false,
          filter: true,
          resizable: true,
          sortable: true,
          width: 100,
        },
        {
          headerName: 'Faktur',
          field: 'faktur',
          editable: false,
          filter: true,
          resizable: true,
          width: 150,
          sortable: true,
          // valueFormatter: this.changeJenis,
        },
        {
          headerName: 'Keterangan',
          field: 'keterangan',
          editable: false,
          filter: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Debet',
          field: 'debet',
          editable: true,
          resizable: true,
          sortable: true,
          width: 150,
          type: 'rightAligned',
          valueFormatter: this.currencyFormatter,
        },
        {
          headerName: 'Kredit',
          field: 'kredit',
          editable: true,
          resizable: true,
          sortable: true,
          width: 150,
          type: 'rightAligned',
          valueFormatter: this.currencyFormatter,
        },
        {
          headerName: 'Saldo',
          field: 'saldo',
          editable: true,
          resizable: true,
          sortable: true,
          width: 150,
          type: 'rightAligned',
          valueFormatter: this.currencyFormatter,
        },
        {
          headerName: 'Username',
          field: 'username',
          editable: true,
          resizable: true,
          sortable: true,
        },
      ],
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return params.data.isPrimary !== undefined && params.data.isPrimary === 1
        },
        'font-weight-bold': (params) => {
          // console.log('params.data.jenis', params.data.jenis)
          return params.data.jenis === 'I'
        },
      },
      // End property

      // Modal Property
      formvisible: false,
      carinasabahtitle: 'Cari',
      inputModal: {
        nama: '',
        alamat: '',
        cabang: '',
      },
    }
  },
  methods: {
    moment,
    // Modal func
    async showModalPengesahan() {
      var res = await lou.customUrlGet2('general/master/2/pengesahan?kode=LaporanBukuBesar')
      this.$refs.modalPengesahan.$data.input = res.data
      // console.log('this.$refs.modalPengesahan.$data.input', this.$refs.modalPengesahan.$data.input)
      this.$refs.modalPengesahan.showModal()
    },
    // End of Modal func
    async exportPdf() {
      // this.amodal.selectedIndex = i
      if (this.rekening !== '' && this.rekening !== null) {
        var imbuhan = ''
        if (this.rekening !== null && this.rekening !== '') {
          if (imbuhan !== '' && imbuhan !== null) {
            imbuhan += '&rekening=' + this.rekening
          } else {
            imbuhan = '?rekening=' + this.rekening
          }
        }
        if (this.tgl1 !== null && this.tgl1 !== '') {
          if (imbuhan !== '' && imbuhan !== null) {
            imbuhan += '&dawal=' + this.tgl1.format('YYYY-MM-DD')
          } else {
            imbuhan = '?dawal=' + this.tgl1.format('YYYY-MM-DD')
          }
        }
        if (this.tgl2 !== null && this.tgl2 !== '') {
          if (imbuhan !== '' && imbuhan !== null) {
            imbuhan += '&dakhir=' + this.tgl2.format('YYYY-MM-DD')
          } else {
            imbuhan = '?dakhir=' + this.tgl2.format('YYYY-MM-DD')
          }
        }
        var res = await lou.ngetDocumentPdf('laporan/akuntansi/bukubesar' + imbuhan + '&preview=pdf', false, false)
        if (res) {
        // console.log("Success", response);
          const blob = new Blob([res], {
            type: 'application/pdf',
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.target = '_blank'
          // link.download = filename
          link.click()
          // const objectUrl = URL.createObjectURL(blob)
          // console.log('objectUrl', objectUrl)
          // this.amodal.ArrayBuffer = objectUrl
          URL.revokeObjectURL(blob)
          setTimeout(() => {
            this.componentKey += 1
            // this.amodalvisible = true
          }, 500)
        }
        // console.log('record.url2_invoice', record.url2_invoice)
        // window.open(record.url2_invoice, '_blank').focus()
      } else {
        lou.shownotif('Peringatan', 'Data Rekening masih kosong!', 'error')
      }
    },
    async getMaster() {
      var res = await lou.customUrlGet2('transaksi/akuntansi/jurnal/list_rekening')
      if (res) {
        this.masterRekening = []
        res.data.forEach((element) => {
          var nobj = {
            label: element.kode + ' - ' + element.keterangan,
            ...element,
          }
          this.masterRekening.push(nobj)
        })
        // console.log('this.masterRekening', this.masterRekening)
      }
    },
    filterDate(date, dateString) {
      this.tgl1 = date[0]
      this.tgl2 = date[1]
      this.getAllData()
    },
    async getAllData(onclick = false) {
      // console.log('this.allMaster', this.allMaster)
      // console.log('this.rekening', this.rekening)
      if (this.rekening !== null && this.rekening !== '') {
        this.please()
      } else {
        lou.shownotif('Info!', 'Anda belum memilih rekening!', 'info')
      }
      // console.log('this.data', this.data)
    },
    async please() {
      this.loadingRefresh = true
      var imbuhan = ''
      if (this.rekening !== null && this.rekening !== '') {
        if (imbuhan !== '' && imbuhan !== null) {
          imbuhan += '&rekening=' + this.rekening
        } else {
          imbuhan = '?rekening=' + this.rekening
        }
      }
      if (this.tgl1 !== null && this.tgl1 !== '') {
        if (imbuhan !== '' && imbuhan !== null) {
          imbuhan += '&dawal=' + this.tgl1.format('YYYY-MM-DD')
        } else {
          imbuhan = '?dawal=' + this.tgl1.format('YYYY-MM-DD')
        }
      }
      if (this.tgl2 !== null && this.tgl2 !== '') {
        if (imbuhan !== '' && imbuhan !== null) {
          imbuhan += '&dakhir=' + this.tgl2.format('YYYY-MM-DD')
        } else {
          imbuhan = '?dakhir=' + this.tgl2.format('YYYY-MM-DD')
        }
      }
      var response = await lou.customUrlGet2(
        'laporan/akuntansi/bukubesar' + imbuhan, false, false,
      )
      if (response) {
        this.rowData = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
        setTimeout(() => {
          this.loadingRefresh = false
        }, 450)
      } else {
        setTimeout(() => {
          this.loadingRefresh = false
        }, 450)
      }
    },
    // ag-grid function
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      // console.log('this.mode', this.mode)
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      // console.log('selectedRows', selectedRows)
    },
    onFirstDataRendered() {
      // this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    async exportExcel() {
      NProgress.start()
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columnDefs)
      var tdata = this.$g.clone(this.rowData)
      var data = []
      tdata.forEach(element => {
        var retv = {
          ...element,
          tgl: element.tgl === null ? '' : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.headerName.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.field,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.field,
            })
            worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value = el[element.field]
            } else {
              worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + dincrement).value = el[element.field]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.field)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.field]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.field])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Laporan Buku Besar.xlsx'
      NProgress.done()
      link.click()
    },
    formatTanggal(v) {
      // console.log('v', v)
      // console.log('ntgl', ntgl)
      return moment(v, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    changeJenis(v) {
      console.log('v', v)
      var ret = v.value === 'I' ? 'Induk' : 'Detail'
      return ret
    },
    currencyFormatter(params) {
      // console.log('params', params)
      var ret = params.value === null ? 'NaN' : lou.curency(params.value)
      return ret === 'NaN' ? '' : ret
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
